import React,{Component} from 'react'; 
import {API_URL} from '../../env.json';
const errStyle = {
  color: 'rgb(228, 8, 8)',
  fontSize: '12px',
};

class ContactForm extends Component{
    constructor(){
        super();
        this.state={ email:'',name:'',subject:'',message:'',email_err:'',name_err:'',subject_err:'',message_err:'',return_msg:'',flag : false }

         this.handleChangeEmail = this.handleChangeEmail.bind(this);
         this.handleChangeName = this.handleChangeName.bind(this);
         this.handleChangeSubject = this.handleChangeSubject.bind(this);
         this.handleChangeMessage = this.handleChangeMessage.bind(this);
         this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    
    handleChangeEmail(e){
      this.setState({email:  e.target.value});
      var EmailReg =  /.+@.+\.[A-Za-z]+$/;
      if(e.target.value === '') 
        this.setState({email_err: 'Ce champ est obligatoire'});
      else if(EmailReg.test(e.target.value)) 
        this.setState({email_err: ''});
      else 
        this.setState({email_err: 'Email non valide'});
    }
      handleChangeName(e){
        this.setState({name:  e.target.value});
          if(e.target.value === '') 
            this.setState({name_err: 'Ce champ est obligatoire'});
          else 
            this.setState({name_err: ''});
      }
      handleChangeSubject(e){
        this.setState({subject:  e.target.value});
          if(e.target.value === '') 
            this.setState({subject_err: 'Ce champ est obligatoire'});
          else 
            this.setState({subject_err: ''});
      }
      handleChangeMessage(e){
        this.setState({message:  e.target.value});
          if(e.target.message === '') 
            this.setState({message_err: 'Ce champ est obligatoire'});
          else 
            this.setState({message_err: ''});
      }

      handleSubmit()
      {
        const data = {
          name: this.state.name,
          email:this.state.email,
          message:this.state.message
        } 
        const url = `${API_URL}/api/send`
        var EmailReg =  /.+@.+\.[A-Za-z]+$/;
          if(this.state.name === '') 
              this.setState({name_err: 'Ce champ est obligatoire'});
          if(this.state.email === '')
              this.setState({email_err: 'Ce champ est obligatoire'});
          if(!EmailReg.test(this.state.email)) 
              this.setState({email_err: 'Email non valide'});
          if(this.state.subject === '')
             this.setState({subject_err: 'Ce champ est obligatoire'});
             if(this.state.message === '')
             this.setState({message_err: 'Ce champ est obligatoire'});

             if(this.state.name === '' || this.state.email === ''|| !EmailReg.test(this.state.email) || this.state.subject === '' || this.state.message === '')
             {
                this.setState({return_msg: 'Merci de remplir correctement tous les champs obligatoires', flag: true });
             }
             else
             {
                   fetch(url,  {mode: 'cors'}, {
                method: "POST", 
                body: JSON.stringify(data),
                headers: {
                   'Content-Type': 'application/json',
                   'Access-Control-Allow-Origin':'*'
                   
                },
            }) 
            .then( (res) => (res.json()))
            .catch(error =>{})
            .then( ); 
                
                this.setState({return_msg: 'Merci, votre message a bien été envoyée', flag: true});
                this.resetForm()
             }    
      }
      resetForm(){
        this.setState({name: '', email: '',subject:'', message: ''})
      }
   
    render(){

    return(
  
            <div className="contact-form">
              <div id="message">
                  {this.state.flag ? 
                        <div className="alert ">
                           {this.state.return_msg ==='Merci, votre message a bien été envoyée' ? 
                        <div className="alert alert-success">
                        <strong>{this.state.return_msg}</strong>
                        </div>
                    :  <div className="alert alert-danger">
                    <strong>{this.state.return_msg}</strong>
                    </div>}
                        </div>
                    : null}
                   
              </div> 
              <form method="post" id="contactForm">
                  <div className="row">
                  <div className="col-md-6 col-sm-12">
                      <div className="input-group"> 
                          <input type="text" id="name" value={this.state.name} onChange={this.handleChangeName} className="form-control" placeholder="Nom Prénom " /> 
                          <span style ={errStyle} id="err">{this.state.name_err}</span>
                      </div>
            
                        <div className="input-group"> 
                          <input type="text" className="form-control" value={this.state.email} onChange={this.handleChangeEmail} id="email" placeholder="Email" />
                          <span style ={errStyle} id="err">{this.state.email_err}</span>
                      </div> 
                      <div className="input-group"> 
                          <input type="text" id="tel" value={this.state.subject} onChange={this.handleChangeSubject} className="form-control" placeholder="Numéro" />
                          <span style ={errStyle} id="err">{this.state.subject_err}</span>
                      </div> 
                  </div> 
                  <div className="form-group col-md-6 col-sm-12">
                      <div className="input-group input_group_full_width"> 
                          <textarea name="comments" id="message" value={this.state.message} onChange={this.handleChangeMessage} rows="6" className="form-control" placeholder="Message"></textarea>
                          <span style ={errStyle} id="err">{this.state.message_err}</span>
                      </div> 
                  </div> 
                  <div className="col-sm-12">
                    <button type="button" name="send" onClick={this.handleSubmit} className="submit-button btn btn-chos" value="Submit">Envoyer</button>
                    <div id="simple-msg"></div>
                  </div>
   
              </div> 
              </form> 
            </div> 


        )

    }
}

 
export default ContactForm;